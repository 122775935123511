import React, { useCallback, useState } from 'react';
import { PaymentMethod, PaymentMethodType } from 'shared/types/cart/Payment';
import { usePaymentMethods } from 'frontastic';
import { PaymentData, PaymentProvider } from '../types';

export const PaymentProviderContext = React.createContext<PaymentProvider>({} as PaymentProvider);

const CustomPaymentProvider = ({ children }: React.PropsWithChildren) => {
  const avalilablePaymentMethods = usePaymentMethods();

  const [processing, setProcessing] = useState(false);

  const getPaymentMethods = useCallback(() => {
    const supportedTypes = {
      przelewy24: {
        name: 'Przelewy24',
        image: {
          src: '/images/przelewy24.png',
        },
      },
      cash_on_delivery: {
        name: 'Płatność przy odbiorze (+5,00 zł)',
        image: {
          src: '/images/cash-on-delivery.png',
        },
      },
    } as Record<PaymentMethodType, Partial<PaymentMethod>>;

    const paymentMethods = avalilablePaymentMethods.getPaymentMethods();

    return paymentMethods
      .filter((paymentMethod) => paymentMethod.type in supportedTypes)
      .map((paymentMethod) => ({
        ...paymentMethod,
        ...supportedTypes[paymentMethod.type],
      }));
  }, [avalilablePaymentMethods.getPaymentMethods]); //eslint-disable-line

  const [paymentData, setPaymentData] = useState<PaymentData>({
    type: 'przelewy24',
  } as PaymentData);

  return (
    <PaymentProviderContext.Provider
      value={{
        processing,
        setProcessing,
        getPaymentMethods,
        paymentData,
        setPaymentData,
      }}
    >
      {children}
    </PaymentProviderContext.Provider>
  );
};

export default CustomPaymentProvider;
