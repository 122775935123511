import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { PaymentMethod } from 'shared/types/cart/Payment';
import { useCheckout } from 'components/commercetools-ui/organisms/checkout/provider';
import Preview from '../wrapper';

const PaymentPreview = () => {
  const { getPaymentMethods, paymentData } = useCheckout();

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const fetchPaymentMethods = useCallback(async () => {
    const paymentMethods = getPaymentMethods();

    setPaymentMethods(paymentMethods);
  }, [getPaymentMethods]);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  const paymentMethod = useMemo(() => {
    if (!paymentMethods) return;

    const method = paymentMethods.find((method) => method.type === paymentData.type);

    return {
      name: method?.name ?? '',
      image: method?.image,
    };
  }, [paymentMethods, paymentData]);

  return (
    <Preview>
      <div className="border-neutral-2 lg:border-t">
        <div className="flex items-center justify-between lg:pt-24">
          <p className="text-16 leading-[20px] text-neutral-4">{paymentMethod?.name}</p>

          {/* eslint-disable-next-line */}
          {paymentMethod?.image?.src && <img src={paymentMethod.image.src} className="h-[40px]" />}
        </div>
      </div>
    </Preview>
  );
};

export default PaymentPreview;
