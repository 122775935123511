import { useCallback } from 'react';
import { useRouter } from 'next/navigation';
import toast from 'react-hot-toast';
import { LineItem } from 'shared/types/cart';
import { PURCHASE } from 'helpers/constants/events';
import { useFormat } from 'helpers/hooks/useFormat';
import { lineItemToGoogleAnalytics4Item } from 'helpers/utils/mapGoogleAnalytics';
import { useCart } from 'frontastic';
import { useCheckout } from '../provider';

const usePurchase = () => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const router = useRouter();
  const {
    transaction,
    data,
    hasOutOfStockItems,
    hasMoreItemsThanInStock,
    orderCartPrzelewy24,
    orderCartCashOnDelivery,
  } = useCart();
  const { setProcessing, paymentData } = useCheckout();

  const trackPurchase = useCallback(async (lineItems: Array<LineItem>, transaction_id: string) => {
    const googleAnalytics4Items = lineItems.map((lineItem) => ({
      ...lineItemToGoogleAnalytics4Item(lineItem),
      quantity: lineItem?.count ?? 0,
    }));
    const currency = 'PLN';
    const value = Number(
      googleAnalytics4Items
        .reduce((accumulator, currentValue) => accumulator + currentValue.quantity * currentValue.price, 0)
        .toFixed(2),
    );

    gtag('event', PURCHASE, { currency, value, transaction_id, items: googleAnalytics4Items });
  }, []);

  const purchase = useCallback(async () => {
    if (!data?.shippingAddress || !data?.billingAddress || !data?.shippingInfo) return;

    if (hasOutOfStockItems) {
      const outOfStockItems = data?.lineItems?.filter((lineItem) => lineItem.variant?.isOnStock) ?? [];

      toast.error(
        `${formatCheckoutMessage({
          id: 'items.outOfStock',
          defaultMessage: 'The following items are out of stock',
        })}:\n\n
            ${outOfStockItems.map((item) => `- ${item.name} \n\n`)}
          `,
        { style: { alignItems: 'flex-end', flexDirection: 'row-reverse' } },
      );
      return;
    }

    if (hasMoreItemsThanInStock) {
      const moreItemsThanInStock =
        data?.lineItems?.filter((lineItem) => {
          if (lineItem.count && lineItem.variant?.availableQuantity) {
            return lineItem.count > lineItem.variant?.availableQuantity;
          }
        }) ?? [];
      const maximumLocalised = formatCheckoutMessage({
        id: 'items.maximum',
        defaultMessage: 'maximum',
      });
      const itemList = moreItemsThanInStock.map(
        (item) => `- ${item.name}: ${maximumLocalised} ${item.variant?.availableQuantity}\n\n`,
      );
      toast.error(
        `${formatCheckoutMessage({
          id: 'items.limitedStock',
          defaultMessage: 'The following items have exceeded available quantity',
        })}:\n\n
            ${itemList}
          `,
        { style: { alignItems: 'flex-end', flexDirection: 'row-reverse' } },
      );
      return;
    }

    if (!transaction.total.centAmount) {
      toast.error(
        `${formatCheckoutMessage({
          id: 'payment.failed',
          defaultMessage: 'We could not process your payment, please try again later.',
        })}`,
      );

      return;
    }

    setProcessing(true);

    if (paymentData.type === 'cash_on_delivery') {
      const response = await orderCartCashOnDelivery();

      if (response.orderId) {
        trackPurchase(response.lineItems ?? [], response.orderId);
        router.push(`/thank-you?orderId=${response.orderId}`);
      } else {
        toast.error(
          `${formatCheckoutMessage({
            id: 'payment.failed',
            defaultMessage: 'We could not process your payment, please try again later.',
          })}`,
        );
      }
    } else {
      const response = await orderCartPrzelewy24();

      if (response.p24Url) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        trackPurchase(response.lineItems ?? [], response.orderId!);
        router.push(response.p24Url);
      } else {
        toast.error(
          `${formatCheckoutMessage({
            id: 'payment.failed',
            defaultMessage: 'We could not process your payment, please try again later.',
          })}`,
        );
      }
    }

    setProcessing(false);
  }, [
    setProcessing,
    hasOutOfStockItems,
    hasMoreItemsThanInStock,
    transaction.total,
    router,
    data,
    paymentData,
    orderCartPrzelewy24,
    orderCartCashOnDelivery,
    formatCheckoutMessage,
    trackPurchase,
  ]);

  return { purchase };
};

export default usePurchase;
