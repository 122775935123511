import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'next/navigation';
import toast from 'react-hot-toast';
import { ShippingMethod } from 'shared/types/cart';
import Button from 'components/commercetools-ui/atoms/button';
import Radio from 'components/commercetools-ui/atoms/radio';
import DPDPickupLocationModal from 'components/commercetools-ui/organisms/checkout/components/dpd-pickup-location-modal';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import { useCart } from 'frontastic';
import { SHIPPING_METHOD_DPD_PICKUP } from '../../constants/shippingMethodKeys';

export interface Props {
  goToNextStep: () => void;
  pickupPoint: string | undefined;
  setPickupPoint: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const Shipping: React.FC<Props> = ({ goToNextStep, pickupPoint, setPickupPoint }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const { locale } = useParams();

  const [loading, setLoading] = useState(true);

  const { data, setShippingMethod, setDPDShippingMethod } = useCart();

  const [selectedId, setSelectedId] = useState('');

  const isFreeShipping = data?.shippingInfo?.price.centAmount == 0;

  const shippingMethods = useMemo(() => data?.availableShippingMethods ?? [], [data?.availableShippingMethods]);

  useEffect(() => {
    if (data?.availableShippingMethods) {
      if (!selectedId) {
        setPickupPoint(undefined);
        const defaultShippingMethod = data.availableShippingMethods.find((shippingMethod) => shippingMethod.isDefault);
        if (defaultShippingMethod) {
          setSelectedId(defaultShippingMethod.shippingMethodId);
        }
      }
      setLoading(false);
    }
  }, [data, selectedId, setPickupPoint]);

  const { processing, startProcessing, stopProcessing } = useProcessing();

  const isPickupPointSelected = useCallback(() => {
    const selectedShippingMethod = shippingMethods.find(
      (shippingMethod) => shippingMethod.shippingMethodId === selectedId,
    );

    return selectedShippingMethod?.key === SHIPPING_METHOD_DPD_PICKUP;
  }, [selectedId, shippingMethods]);

  const submit = useCallback(async () => {
    if (!selectedId || processing) return;

    startProcessing();

    try {
      pickupPoint ? await setDPDShippingMethod(selectedId, pickupPoint) : await setShippingMethod(selectedId);

      stopProcessing();
      goToNextStep();
    } catch (error) {
      toast.error(
        formatCheckoutMessage({
          id: 'shippingMethod.error',
          defaultMessage: 'It is not possible to choose shipping method.',
        }),
        { position: 'bottom-left' },
      );

      stopProcessing();
    }
  }, [
    selectedId,
    processing,
    startProcessing,
    pickupPoint,
    setDPDShippingMethod,
    setShippingMethod,
    stopProcessing,
    goToNextStep,
    formatCheckoutMessage,
  ]);

  const shippingMethodCard = (shippingMethod: ShippingMethod) => (
    <div className="flex cursor-pointer items-center justify-between border border-neutral-2 p-24">
      <div className="flex items-center">
        <Radio name="checkout-shipping-method" checked={shippingMethod.shippingMethodId === selectedId} />
        <div>
          <p className="text-16 leading-[20px] text-neutral-4">{shippingMethod.name}</p>
        </div>
      </div>

      <span className="text-16 leading-[20px] text-neutral-4">
        {isFreeShipping
          ? formatCheckoutMessage({ id: 'freeShipping', defaultMessage: 'Free Shipping' })
          : CurrencyHelpers.formatForCurrency(shippingMethod.rates?.[0]?.price ?? {}, locale)}
      </span>
    </div>
  );

  return loading ? null : (
    <div className="flex flex-col gap-20">
      <div className="grid gap-12 bg-white lg:grid-cols-2">
        {shippingMethods.map((shippingMethod) =>
          shippingMethod.key === SHIPPING_METHOD_DPD_PICKUP ? (
            <div key={shippingMethod.shippingMethodId} onClick={() => setSelectedId(shippingMethod.shippingMethodId)}>
              <DPDPickupLocationModal pickupType={shippingMethod.pickupType} setPickupPoint={setPickupPoint}>
                {shippingMethodCard(shippingMethod)}
              </DPDPickupLocationModal>
            </div>
          ) : (
            <div
              key={shippingMethod.shippingMethodId}
              onClick={() => {
                setSelectedId(shippingMethod.shippingMethodId);
                setPickupPoint(undefined);
              }}
            >
              {shippingMethodCard(shippingMethod)}
            </div>
          ),
        )}
      </div>

      <Button
        variant="primary"
        className="w-full min-w-[200px] md:text-16 lg:w-fit lg:px-36"
        loading={processing}
        disabled={selectedId === '' || (isPickupPointSelected() && !pickupPoint)}
        type="submit"
        onClick={submit}
      >
        {formatCartMessage({ id: 'continue.to', defaultMessage: 'Continue to' })}{' '}
        <span className="lowercase">{formatCartMessage({ id: 'payment', defaultMessage: 'Payment' })}</span>
      </Button>
    </div>
  );
};

export default Shipping;
