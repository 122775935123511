import React from 'react';
import Link from 'components/commercetools-ui/atoms/link';
import { Reference } from 'types/reference';

/*
 * This function converts a string with link placeholders "{link}"
 * into a valid JSX component that interpolates the plain text
 * with <Link> components in the place of the placeholders.
 * See examples at the end of the file.
 */

export default function createTextWithLinkComponent(
  text: string,
  linkLabel: string,
  linkURL: Reference,
  overrideLinkClassName = null,
  additionalLinkClassName = null,
) {
  const baseLinkClassName = 'underline';
  const linkClassName = overrideLinkClassName || baseLinkClassName + ` ${additionalLinkClassName}`;
  const parts = text.split('{link}');

  return (
    <div>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < parts.length - 1 && (
            <Link link={linkURL} className={linkClassName}>
              {linkLabel}
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

/*
 * Example 1 input:
 *   "This is a {link}!"
 * Example 1 output:
 *   <div>
 *     This is a
 *     <Link ...>{linkLabel}</Link>
 *     !
 *   </div>
 *
 * Example 2 input:
 *   "This {link} sends the user to {link}"
 * Example 2 output:
 *   <div>
 *     This
 *     <Link ...>{linkLabel}</Link>
 *     sends the user to
 *     <Link ...>{linkLabel}</Link>
 *   </div>
 */
