'use client';

import React from 'react';
import Checkout, { CheckoutWrappedProps } from 'components/commercetools-ui/organisms/checkout';
import { TasticProps } from '../types';

const CheckoutTastic = ({ data }: TasticProps<CheckoutWrappedProps>) => {
  return <Checkout {...data} />;
};

export default CheckoutTastic;
