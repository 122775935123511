import React from 'react';

interface Props {
  className?: string;
}

const Preview = ({ children, className }: React.PropsWithChildren<Props>) => {
  return (
    /*eslint-disable-next-line tailwindcss/no-custom-classname */
    <div className={className}>{children}</div>
  );
};

export default Preview;
