import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useParams } from 'next/navigation';
import { PaymentMethod } from 'shared/types/cart/Payment';
import Button from 'components/commercetools-ui/atoms/button';
import Container from 'components/commercetools-ui/atoms/container';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import createTextWithLinkComponent from 'helpers/utils/createTextWithLinkComponent';
import { Reference } from 'types/reference';
import { useAccount, useCart } from 'frontastic';
import Header, { Props as HeaderProps } from './components/header';
import Secure from './components/secure';
import Steps from './components/steps';
import usePurchase from './hooks/usePurchase';
import CheckoutProvider, { useCheckout } from './provider';
import useTrack from './useTrack';
import AnnouncementBar, { AnnouncementVariant } from '../announcement-bar';
import OrderOverview from '../order-overview';
import { RegulationTextConfig } from '../order-overview/types';
import useCostsData from '../order-payment-section/hooks/useCostsData';

// Added in checkout-v2 schema
export type RegulationText = {
  text?: string;
  linkLabel?: string;
  linkURL?: Reference;
};

export type PaymentMethods = {
  paymentMethods: Array<PaymentMethod>;
};

// Added in checkout-v3 schema
export type AnnouncementBar = {
  announcementBarText: string;
};

// Added in checkout-v5 schema
interface CheckoutInfoMessage {
  headline?: string;
  text?: string;
  label?: string;
  icon?: string;
}
export type InfoMessages = {
  infoMessages?: CheckoutInfoMessage[];
};

export type CheckoutWrappedProps = HeaderProps & RegulationText & PaymentMethods & AnnouncementBar & InfoMessages;

const CheckoutWrapped: React.FC<CheckoutWrappedProps> = ({
  paymentMethods,
  text,
  linkLabel,
  linkURL,
  announcementBarText,
  infoMessages,
  ...emptyState
}) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const title = formatCartMessage({ id: 'order.summary', defaultMessage: 'Order summary' });

  const [isFinalStep, setIsFinalStep] = useState(false);
  const { processing } = useCheckout();
  const { purchase } = usePurchase();
  const { data, isValid } = useCart();
  const { loggedIn } = useAccount();

  // For <OrderOverview>

  // The CTA button for Order Overview is defined on this component together with its "disabled" condition
  // Therefore it is necessary to define the state of the checkbox here as well
  // In addition, this state is also used on the <Step> component when in mobile view,
  // because the Regulation Text is not inside Order Overview but instead appears at the bottom of the page
  const [regulationCheckboxChecked, setRegulationCheckboxChecked] = useState(false);
  let regulationFinalText = null;
  if (text && linkLabel && linkURL) {
    regulationFinalText = createTextWithLinkComponent(text, linkLabel, linkURL);
  }
  const regulationTextConfig: RegulationTextConfig = {
    regulationFinalText,
    checkbox: { value: regulationCheckboxChecked, set: setRegulationCheckboxChecked },
  };

  const disabled = regulationTextConfig.regulationFinalText ? !isFinalStep || !regulationCheckboxChecked : !isFinalStep;

  // For <CtaButton>

  const ctaButton: ReactNode = useMemo(() => {
    return (
      <Button
        variant="primary"
        disabled={disabled}
        className="w-full"
        type="submit"
        onClick={purchase}
        loading={processing}
      >
        {formatCheckoutMessage({ id: 'complete.purchase', defaultMessage: 'Complete purchase' })}
      </Button>
    );
  }, [disabled, formatCheckoutMessage, processing, purchase]);

  // For <Costs>

  const { locale } = useParams();
  const { loading, costsToRender, total } = useCostsData({ dataReference: 'cart' });

  const costsPropsFromParentPage = {
    locale,
    loading,
    costsToRender,
    total,
    freeShipping: data?.freeShipping,
  };

  // For <AnnouncementBar>

  const announcementBarData = {
    text: announcementBarText,
    highlightedSubstring: '',
    variant: 'checkout' as AnnouncementVariant,
  };

  const lineItems = (data?.lineItems ?? []).filter((lineItem) => lineItem.variant?.isOnStock);
  const { ref } = useTrack({ lineItems });

  // Render

  if (!isValid) return <Redirect target="/cart" />;

  if (!loggedIn) return <Redirect target="/login" />;

  return (
    <div ref={ref} className="min-h-screen bg-neutral-200">
      <AnnouncementBar {...announcementBarData} />

      <Header {...emptyState} />
      <Container>
        <div className="flex flex-col gap-20 pb-40 lg:mx-[48px] lg:gap-32 lg:pb-48">
          {/* Secure Checkout headline */}
          <Secure />
          {/* Main content */}
          <div className="flex-row-reverse items-start gap-12 lg:flex">
            {/* Checkout Order Overview */}
            <OrderOverview
              costsPropsFromParentPage={costsPropsFromParentPage}
              ctaButton={ctaButton}
              page="checkout"
              paymentMethods={paymentMethods}
              title={title}
              // Optional props
              regulationTextConfig={regulationTextConfig}
            />

            {/* Checkout steps */}
            <Steps
              onPurchase={purchase}
              onFinalStepChange={setIsFinalStep}
              regulationTextConfig={regulationTextConfig}
              infoMessages={infoMessages}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

const Checkout: FC<CheckoutWrappedProps> = (props) => (
  <CheckoutProvider>
    <CheckoutWrapped {...props} />
  </CheckoutProvider>
);

export default Checkout;
