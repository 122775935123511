import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import InfoMessage from 'components/commercetools-ui/organisms/info-message';
import { RegulationTextConfig } from 'components/commercetools-ui/organisms/order-overview/types';
import { useFormat } from 'helpers/hooks/useFormat';
import usePath from 'helpers/hooks/usePath';
import { useCart } from 'frontastic';
import { SHIPPING_METHOD_DPD_PICKUP } from './constants/shippingMethodKeys';
import AddressesPreview from './previews/addresses';
import PaymentPreview from './previews/payment';
import ShippingPreview from './previews/shipping';
import Addresses from './sections/addresses';
import DPDAddress from './sections/addresses/dpd';
import Payment from './sections/payment';
import Shipping from './sections/shipping';
import { InfoMessages } from '../..';
import { useCheckout } from '../../provider';
import AddressModal from '../create-address-modal';
import Step from '../step';

interface Props {
  onPurchase: () => void;
  onFinalStepChange: (isFinalStep: boolean) => void;
  regulationTextConfig?: RegulationTextConfig;
}

type StepsProps = Props & InfoMessages;

const Steps: React.FC<StepsProps> = ({ onPurchase, onFinalStepChange, infoMessages, regulationTextConfig = null }) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const router = useRouter();

  const searchParams = useSearchParams();

  const step = +(searchParams.get('step') ?? 0);

  const { pathWithoutQuery } = usePath();

  const { processing } = useCheckout();
  const { data } = useCart();

  const [active, setActive] = useState<number>(0);
  const [pickupPoint, setPickupPoint] = useState<string>();

  const goToNextStep = useCallback(() => {
    setActive(active + 1);
    router.push(`${pathWithoutQuery}?step=${active + 1}`);
  }, [active, router, pathWithoutQuery]);

  useEffect(() => {
    const isShippingMethodDPDPickup = data?.shippingInfo?.key === SHIPPING_METHOD_DPD_PICKUP;
    //STEP 0: SHIPPING METHOD. IF PAGE RELOAD WE GET VALUE FROM PREVIOUSLY SET VALUE
    if (step > 0 && isShippingMethodDPDPickup) {
      setPickupPoint(data?.shippingAddress?.externalId);
    }
    setActive(step);
  }, [step, data]);

  const onEdit = useCallback((index: number) => setActive(index), []);

  const steps = useMemo(() => {
    return [
      {
        label: formatCheckoutMessage({ id: 'shipping', defaultMessage: 'Shipping' }),
        Component: <Shipping goToNextStep={goToNextStep} pickupPoint={pickupPoint} setPickupPoint={setPickupPoint} />,
        Preview: <ShippingPreview pickupPoint={pickupPoint} />,
      },
      pickupPoint
        ? {
            label: formatCheckoutMessage({ id: 'addresses', defaultMessage: 'Addresses' }),
            Component: data ? <DPDAddress goToNextStep={goToNextStep} /> : <></>,
            Preview: <AddressesPreview pickupPoint={pickupPoint} />,
          }
        : {
            label: formatCheckoutMessage({ id: 'addresses', defaultMessage: 'Addresses' }),
            Component: data ? <Addresses goToNextStep={goToNextStep} /> : <></>,
            Preview: <AddressesPreview />,
            CTA: <AddressModal />,
          },
      {
        label: formatCheckoutMessage({ id: 'payment', defaultMessage: 'Payment' }),
        Component: <Payment goToNextStep={goToNextStep} pickupPoint={pickupPoint} />,
        Preview: <PaymentPreview />,
      },
    ];
  }, [formatCheckoutMessage, goToNextStep, data, pickupPoint]);

  const isFinalStep = useMemo(() => active === steps.length, [active, steps.length]);

  const disabled = !!(regulationTextConfig?.regulationFinalText && !regulationTextConfig.checkbox.value);

  useEffect(() => {
    onFinalStepChange(isFinalStep);
  }, [isFinalStep, onFinalStepChange]);

  return (
    <div className="px-16 md:px-24 lg:grow lg:px-0">
      <div className="flex flex-col gap-12">
        {steps.map(({ Component, Preview, label, CTA }, index) => (
          <Step
            key={index}
            label={label}
            number={index + 1}
            isExpanded={index === active}
            isCompleted={index < active}
            onEdit={() => onEdit(index)}
            Component={Component}
            Preview={Preview}
            CTA={CTA}
          />
        ))}
        {infoMessages && infoMessages.map((infoMessage, index) => <InfoMessage key={index} {...infoMessage} />)}
      </div>
      {isFinalStep && (
        <div className="mt-24 lg:hidden">
          {regulationTextConfig?.regulationFinalText && (
            <label className="flex items-start gap-12 pb-20">
              <Checkbox
                checked={regulationTextConfig.checkbox.value}
                onChange={() => regulationTextConfig.checkbox.set(!regulationTextConfig.checkbox.value)}
              />
              {regulationTextConfig.regulationFinalText}
            </label>
          )}
          <Button
            variant="primary"
            className="w-full"
            type="submit"
            onClick={onPurchase}
            loading={processing}
            disabled={disabled}
          >
            {formatCheckoutMessage({ id: 'complete.purchase', defaultMessage: 'Complete purchase' })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Steps;
