import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

const Secure = () => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  return (
    <div className="mt-32 px-16 md:px-24 lg:px-0">
      <p className="text-24 font-bold capitalize leading-[30px] text-neutral-4 lg:text-32 lg:leading-[40px]">
        {formatCheckoutMessage({ id: 'secure.checkout', defaultMessage: 'Secure checkout' })}
      </p>
    </div>
  );
};

export default Secure;
