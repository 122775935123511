import React, { useContext } from 'react';
import CustomPaymentProvider, { PaymentProviderContext } from './payment/custom';

const CheckoutContext = React.createContext({});

const CheckoutProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <CheckoutContext.Provider value={{}}>
      <CustomPaymentProvider>{children}</CustomPaymentProvider>
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;

export const useCheckout = () => useContext(PaymentProviderContext);
