import { useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { LineItem } from 'shared/types/cart';
import { BEGIN_CHECKOUT } from 'helpers/constants/events';
import { lineItemToGoogleAnalytics4Item } from 'helpers/utils/mapGoogleAnalytics';

interface Options {
  lineItems: Array<LineItem>;
}

const useTrack = ({ lineItems }: Options) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  const trackedBeginCheckout = useRef(false);

  const trackBeginCheckout = useCallback(async () => {
    if (inView && !trackedBeginCheckout.current) {
      const googleAnalytics4Items = lineItems.map((lineItem) => ({
        ...lineItemToGoogleAnalytics4Item(lineItem),
        quantity: lineItem?.count ?? 0,
      }));
      const currency = 'PLN';
      const value = Number(
        googleAnalytics4Items
          .reduce((accumulator, currentValue) => accumulator + currentValue.quantity * currentValue.price, 0)
          .toFixed(2),
      );

      gtag('event', BEGIN_CHECKOUT, { currency, value, items: googleAnalytics4Items });

      trackedBeginCheckout.current = true;
    }
  }, [inView, lineItems]);

  useEffect(() => {
    trackBeginCheckout();
  }, [trackBeginCheckout]);

  return { ref };
};

export default useTrack;
