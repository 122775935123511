import React, { useEffect, useMemo, useState } from 'react';
import Radio from 'components/commercetools-ui/atoms/radio';
import Typography from 'components/commercetools-ui/atoms/typography';
import AddressModal from 'components/commercetools-ui/organisms/checkout/components/create-address-modal';
import InfoIcon from 'components/icons/InfoIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import useMappers from '../../hooks/useMappers';
import { Address } from '../../types';

interface Props {
  className?: string;
  type: 'shipping' | 'billing';
  onSelectAddress: (address: Address) => void;
}

const AccountAddresses: React.FC<Props> = ({ className = '', type, onSelectAddress }) => {
  const { shippingAddresses, billingAddresses, defaultBillingAddress, defaultShippingAddress } = useAccount();
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'error' });
  const { formatMessage } = useFormat({ name: 'checkout' });

  const { accountAddressToAddress } = useMappers();

  const addresses = useMemo(() => {
    const addresses = type === 'shipping' ? shippingAddresses : billingAddresses;
    return addresses.filter(
      (address) =>
        (address.isBillingAddress && address.phone && address.streetName && address.postalCode && address.city) ||
        !address.isBillingAddress,
    );
  }, [type, shippingAddresses, billingAddresses]);

  const [selected, setSelected] = useState<Address>();

  useEffect(() => {
    const billingAddress =
      defaultBillingAddress?.phone &&
      defaultBillingAddress?.streetName &&
      defaultBillingAddress?.postalCode &&
      defaultBillingAddress?.city
        ? defaultBillingAddress
        : null;
    const defaultAddress = type === 'shipping' ? defaultShippingAddress : billingAddress;

    if (defaultAddress) setSelected(accountAddressToAddress(defaultAddress));
  }, [defaultShippingAddress, defaultBillingAddress, accountAddressToAddress, type]);

  useEffect(() => {
    if (selected) onSelectAddress(selected);
  }, [selected, onSelectAddress]);

  const onClickCard = (address: Address) => {
    if (address.phone) {
      setSelected(address);
    }
  };

  return (
    <div className={`flex grid-cols-3 flex-col gap-12 md:grid ${className}`}>
      {addresses
        .map((address) => accountAddressToAddress(address))
        .map((address) => (
          <div
            key={address.addressId}
            className={`${
              !address.phone ? 'border-status-danger-border' : 'border-neutral-2'
            } flex flex-col gap-10 border  px-16 py-24`}
          >
            <div className="flex w-full justify-between">
              <span className="font-bold">
                {address.addressType !== 'billing'
                  ? formatMessage({ id: 'shippingAddress', defaultMessage: 'Shipping address' })
                  : formatMessage({ id: 'billingAddress', defaultMessage: 'Billing address' })}
              </span>
              {address.addressType !== 'billing' && <AddressModal addressToUpdate={address} />}
            </div>
            <div
              key={address.addressId}
              className={`flex  items-center 
               ${!address.phone ? 'cursor-not-allowed' : 'cursor-pointer'}
  `}
              onClick={() => onClickCard(address)}
            >
              <Radio
                className="shrink-0"
                checked={address.addressId === selected?.addressId}
                disabled={!address.phone}
              />
              <p className="max-w-full overflow-hidden truncate text-16 leading-[20px] text-neutral-4">
                <span className="mt-4 block" />
                <span className="font-bold">
                  {address.firstName} {address.lastName}
                </span>
                <span className="mt-4 block" />
                {address.line1} {address.number}
                <span className="mt-4 block" />
                {address.postalCode} {address.city}
                {address.phone ? (
                  <p>
                    <span className="mt-4 block" />
                    {address.phone}
                  </p>
                ) : null}
              </p>
            </div>
            {!address.phone && (
              <div className="flex items-center gap-x-4 text-status-danger-border">
                <span className="h-24 w-24">
                  <InfoIcon />
                </span>
                <Typography className="text-14 leading-[17.5px]">
                  {formatCheckoutMessage({
                    id: 'address.update',
                    defaultMessage:
                      'This address is missing a phone number. Please add one via the Edit Button to use this address',
                  })}
                </Typography>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default AccountAddresses;
