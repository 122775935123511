import React, { useCallback } from 'react';
import Input from 'components/commercetools-ui/atoms/input';
import { Fields } from './types';
import { Address } from '../../types';

interface Props {
  className?: string;
  address: Address;
  fields: () => Fields[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
}

const AddressForm = ({
  className: containerClassName,
  fields,
  address,
  onChange,
  onSubmit,
  children,
}: React.PropsWithChildren<Props>) => {
  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit?.();
    },
    [onSubmit],
  );

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={`grid grid-cols-4 gap-12 ${containerClassName}`}>
        {fields().map(
          ({ name, label, labelDesc, placeholder, tooltip, type, required, className, render, validationSchema }) => (
            <React.Fragment key={name}>
              <div className={className}>
                <Input
                  name={name}
                  label={label}
                  labelDesc={labelDesc}
                  type={type}
                  required={required}
                  value={address[name as keyof Address] as string}
                  labelPosition="top"
                  onChange={onChange}
                  validationSchema={validationSchema}
                  placeholder={placeholder}
                  tooltip={tooltip}
                />
                {render?.()}
              </div>
            </React.Fragment>
          ),
        )}
      </div>
      {children}
    </form>
  );
};

export default AddressForm;
